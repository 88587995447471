<template>
  <div>
    <BaseInput
      v-model="firstName"
      :modelValue="firstName"
      type="text"
      label="Teste"
      class="px-5"
    />
    {{ firstName }}
  </div>
  <div>COMPLETE FORM</div>
</template>

<script setup>
import BaseInput from "@/components/BaseInput";
import { ref } from "@vue/reactivity";

const firstName = ref("");
</script>

<style>
</style>