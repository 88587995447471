<template>
    <div v-for="post in posts" :key="post">
        <single-post :post="post"></single-post>
    </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import SinglePost from './SinglePost.vue'
export default {
    name:'PostList',
    components:{
        SinglePost
    },
    props:['posts'],
    setup({posts}){
        const snippet = computed(() => {
            return posts.body
        })
    }

}
</script>

<style>

</style>