import { ref } from 'vue'
import { useLoadPosts } from '@/composable/firebase'
const getPosts = async () => {
    try{            
            const error = ref(null)
            const posts = await  useLoadPosts()
            return {posts, error}
        }catch(err){
            error.value = err.message
            console.log(err.message)
        }
    return {posts, error}
    }
export default getPosts

/* **********************************************
 Created by: Lucas, Travis
 Created at: April 15, 2022
 What it does: pull all posts
 What it returns: { posts, errors, load[func] }

 Edited by:
 Edited at:
 Changes:

 Edited by:
 Edited at:
 Changes:

 Edited by:
 Edited at:
 Changes:

********************************************** */