<template>
  <div class="md:px-auto sm:px-auto lg:px-40">
    <barber-complete-profile-form
      v-if="localProfile && localProfile === 'barberUncompletedProfile'"
    ></barber-complete-profile-form>
    <barber-home
      v-else-if="localProfile && localProfile === 'barber'"
    ></barber-home>
    <customer-complete-profile-form
      v-if="localProfile && localProfile === 'customerUncompletedProfile'"
    ></customer-complete-profile-form>
    <customer-home
      v-if="localProfile && localProfile === 'customer'"
    ></customer-home>
  </div>
</template>

<script>
import PostList from "@/components/PostList.vue";
import Spinner from "@/components/Spinner";
import BarberHome from "@/views/Barber/BarberHome";
import CustomerCompleteProfileForm from "@/views/Customer/CustomerCompleteProfileForm";
import CustomerHome from "@/views/Customer/CustomerHome";
import BarberCompleteProfileForm from "@/views/Barber/BarberCompleteProfileForm";
import { mapState, useStore } from "vuex";
import { ref, reactive } from "vue";
import { computed, onBeforeMount, onMounted } from "@vue/runtime-core";
export default {
  name: "HomeView",
  components: {
    PostList,
    Spinner,
    Headers,
    "barber-home": BarberHome,
    "customer-home": CustomerHome,
    "barber-complete-profile-form": BarberCompleteProfileForm,
    "customer-complete-profile-form": CustomerCompleteProfileForm,
  },
  setup() {
    const store = useStore();
    const profile = computed(() => store.state.profile);
    console.log(profile.value);
    let localProfile = profile.value.isCustomer // checks if it is a customer
      ? profile.value.isCustomer && !profile.value.completedProfile // if it's a customer witn uncompleted profile
        ? ref("customerUncompletedProfile") // Returns customer with uncompleted profile
        : ref("customer") // Returns customer with completed profile
      : profile.value.isBarber // Checks if it is a barber
      ? profile.value.isBarber && !profile.value.completedProfile // Checks if it is a barber with uncompleted profile
        ? ref("barberUncompletedProfile") // Returns barber with uncompleted profile
        : ref("barber") // returns barber with completed profile
      : false;

    console.log(localProfile.value);
    return { localProfile };
  },
  computed: {
    ...mapState(["barber"]),
  },
  mounted() {
    console.log("Barber:", this.barber);
  },
  beforeMount() {},
  created() {
    // console.log('created')
  },
  mounted() {
    // console.log('mounted')
  },
};
</script>


<style >
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
