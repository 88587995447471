<template>
  <div class="w-full border rounded-xl py-4 bg-gray-50 mt-2">
    <router-link :to="{ name: 'post-details', params: { id: post.id } }">
      <h3 class="text-2xl text-left border-b border-gray-500 mx-6">
        {{ post.title }}
      </h3>
    </router-link>
    <p class="mt-3 text-left py-3 mx-6">{{ snippet }}</p>
    <div class="flex justify-end mx-6 flex-col md:flex-row lg:flex-row">
      <span
        class="
          mx-1
          my-1
          text-xs
          font-semibold
          py-1
          px-3
          hover:bg-gray-700
          rounded-lg
          hover:text-white
        "
        v-for="tag in post.tags"
        :key="tag"
      >
        <router-link :to="{ name: 'tags', params: { tag: tag } }">
          #{{ tag }}
        </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
export default {
  props: ["post"],
  setup({ post }) {
    const snippet = computed(() => {
      return post.body.substring(0, 100);
    });
    return { snippet };
  },
};
</script>

<style>
</style>