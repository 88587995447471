import { useLoadPosts } from "@/composable/firebase"
import getPosts from "@/composable/getPosts"
export default {
    async loadPosts({commit}){
        const posts = await useLoadPosts()
        console.log(posts)
        commit('loadPosts',posts.value)
        // if(error.value){
        //     commit('setErrors',error.value)
        // }
    },
    async filterPosts({commit},payload){
        const posts = await useLoadPosts()
        const result = posts.value.filter((p) => p.tags.includes(payload.filterParam))
        commit('updatePosts',result)
        // if(error.value){
        //     commit('setErrors',error.value)
        // }
    }
}