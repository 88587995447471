<template>
    <div class="spin mx-auto my-auto"></div>
</template>

<script>
export default {

}
</script>

<style>
    .spin {
        display: block; 
        width: 40px;
        height: 40px;
        margin: 30px;
        border: 3px solid transparent;
        border-radius: 50%;
        border-top-color: #14B8A6;
        animation: spin 1s ease infinite
    }

    @keyframes spin {
        to { transform: rotateZ(360deg)}
        
    }
</style>