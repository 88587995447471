import { createStore } from "vuex";
import posts from "./posts";
import { GetBarberOrCustomer } from "@/composable/firebase";
const store = createStore({
    state:{
        posts:[],
        user: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : false,
        profile: localStorage.getItem('profileInfo')  ? JSON.parse(localStorage.getItem('profileInfo')) : false,
    },
    mutations:{
        SET_LOGGED_IN(state){
            state.user = {
                isLoggedIn:true
            }
        },
        SET_USER(state, payload){
            state.user = payload;
        },
        SET_LOGGED_OUT(state){
            state.user=null
            localStorage.clear();
        },
        SET_PROFILE(state, barberInfo){
            state.profile= barberInfo;
        }
    },
    actions:{
        async login({commit}, payload){
            let profile = await GetBarberOrCustomer(payload.email);
            if(profile) {            
                localStorage.setItem('profileInfo', JSON.stringify(profile));    
                commit('SET_PROFILE', profile);
            }
            if(payload) {
                localStorage.setItem('userInfo', JSON.stringify(payload));
            }
            commit('SET_LOGGED_IN', payload);
            commit('SET_USER', payload);
        },
        
        fetchUser({commit}, user){
            commit('SET_LOGGED_IN',user !== null)
            if(user){
                commit('SET_USER',user)
            }else{
                commit('SET_LOGGED_OUT')
            }
        }
    },
    modules:{
        postsModule:posts
    }
})



export default store