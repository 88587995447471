import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {getAuth} from 'firebase/auth'
import { getCurrentUser } from '../composable/firebase'
const routes = [
  {
    path: '/',
    name: 'home',
    component:HomeView,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/posts/:id',
    name: 'post-details',
    component:() => import('../views/Details.vue'),
    props:true,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/create',
    name: 'create',
    component:() => import('../views/Create.vue'),
    props:true,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/tags/:tag',
    name: 'tags',
    component:() => import('../views/Tags.vue'),
    props:true,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component:() => import('../views/Login.vue'),
    props:true
    
  },
  {
    path: '/register',
    name: 'register',
    component:() => import('../views/Register.vue'),
    props:true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( async (to,from,next) =>{
  if(to.matched.some((record)=> record.meta.requiresAuth))
  {
    if(await getCurrentUser())
    {
      next()
    }else
    {
      alert('Acesso não autorizado')
      next('/login')
    }

  }else 
  {
    next();
  }
})
export default router
