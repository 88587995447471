<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { onBeforeMount, ref, watch, watchEffect } from "vue";
export default {
  setup() {},
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
#app {
  font-family: "Poppins", sans-serif;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
