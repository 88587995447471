<template>
  <div class="flex md:justify-around lg:justify-around">
    <section id="head" class="">
      <div class="flex items-center space-x-4">
        <img class="w-10 h-10 rounded-full" src="" alt="" />
        <div class="space-y-1 font-medium text-gray-700">
          <div>Jese Leos</div>
          <div class="text-sm text-gray-500 dark:text-gray-400">
            Joined in August 2014
          </div>
        </div>
      </div>
    </section>
    <section id="skills"></section>
  </div>
</template>

<script>
import { mapState, useStore } from "vuex";

export default {
  setup() {},
  computed: {
    ...mapState(["barber", "user"]),
  },
  mounted() {
    console.log(this.user);
  },
};
</script>

<style>
</style>