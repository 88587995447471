import getPosts from "@/composable/getPosts"

export default {
    allPosts(state){
        console.log('Getters posts found: ',state.posts.length)
        return state.posts
    },
    filteredPosts(state, paramFilter){
        return state.posts
    }
}