import postGetters from './getters'
import postAcions from './actions'
import postMutations from './mutations'
export default {
    namespaced:true,
    state(){
        return{
            posts:[],
            errors:[]
        }
    },
    getters:postGetters,
    mutations:postMutations,
    actions:postAcions
}