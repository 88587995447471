import { ref, onMounted } from 'vue'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { 
    collection, 
    addDoc, 
    getDoc,
    getDocs,
    query,
    where
 } from "firebase/firestore"; 
import { getAuth, 
    onAuthStateChanged, 
    GoogleAuthProvider,
    signInWithPopup
} from 'firebase/auth'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const postsCollection = collection(db,'posts')

/* Creates a post on Firebase */
export const createPost = async (post) => {
    const postRef =  await addDoc(postsCollection,post)
    console.log(`Id of the post ${postRef.id}`)
}

/* Read a post from Firebase */
export const getPost = async (id) => {
    const post = await postsCollection.doc(id).get()
    return post.exists ? post.data() : null
}

/* Delete a post from Firebase */
export const deletePost = (id) => {
    const post = postsCollection.doc(id).delete()
}

export const useLoadPosts = async () => {
    const posts = ref([])
    const querySnapshot  = await getDocs(postsCollection, "posts")
    posts.value = querySnapshot.docs.map((doc) =>({id: doc.id, ...doc.data()}))
    return posts
}

export const SignInWithGoogle = (store, router) => {
    const provider = new GoogleAuthProvider();
    const loginByGoogle = () =>{
        signInWithPopup(getAuth(), provider)
          .then((result) => {            
            store.dispatch("login", result.user);
            router.push("/");
          })
          .catch((error) => {
            console.log(error.message);
          });
        
    }
    return {loginByGoogle}
  };

export const GetBarberOrCustomer = async (email) => {
    
    // Check if it is a barber
    const barberDocRef = doc(db, "barbers", email);
    const docSnap = await getDoc(barberDocRef);
    if(docSnap.exists()) {
        return docSnap.data();
    }else{
        // Check if it is a customer
        const customerDocRef = doc(db, "customers", email);
        const docSnap = await getDoc(customerDocRef);
        if(docSnap.exists()) {
            return docSnap.data();
        }
    }
    return false
}

// Listener example
// const unsub = onSnapshot(doc(db, "barbers", "GqIBN73rnXxrnU2UGmLU"), (doc) => {
//     console.log("Current data: ", doc.data());
// });